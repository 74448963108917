body {
    font-family: 'Inconsolata', monospace;
    background-color: #002352;
    color: #fff;
    text-align: center;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Merriweather', serif;
}

a{
    color: #fff;
}